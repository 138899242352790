import React from 'react'
import { getImage, GatsbyImage, ImageDataLike } from 'gatsby-plugin-image'
import tw from 'twin.macro'

type ImageProp = {
  image: ImageDataLike
  alt: string
}

const Image = tw(GatsbyImage)`
lg:h-80 lg:w-80 h-64 w-64 rounded-full block shadow-lg
`

const CircleImage: React.FC<ImageProp> = ({ image, alt }) => (
  <Image image={getImage(image)} alt={alt} />
)

export default CircleImage

import React from 'react'
import 'twin.macro'

const WrapForm: React.FC = ({ children }) => {
  return (
    <form
      name="contact"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/success/"
      tw="bg-white bg-opacity-10 shadow-lg backdrop-blur-sm rounded-lg px-14 py-12 "
    >
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="bot-field" />
      {children}
    </form>
  )
}
export default WrapForm

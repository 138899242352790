import React from 'react'
import tw, { css } from 'twin.macro'

type ContainerProp = {
  isCenter?: boolean
  isReverse?: boolean
}
const ContainerStyle = ({ isCenter, isReverse }) => css`
  ${tw`flex md:flex-row w-full items-center`};
  ${isCenter ? tw`justify-center` : tw`justify-between`}
  ${isReverse ? tw`flex-col-reverse` : tw`flex-col`}
`

const Container: React.FC<ContainerProp> = ({
  children,
  isCenter,
  isReverse,
}) => {
  return <div css={ContainerStyle({ isCenter, isReverse })}>{children}</div>
}

export default Container

import React from 'react'
import { Link } from 'gatsby'
import tw, { css } from 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'
import Headline from '@/components/atoms/util/Headline'
import SectionStyle from '@/components/atoms/util/SectionStyle'
import SlideShow from '@/components/molecules/SlideShow'
import H3Style from '@/components/atoms/typo/H3Style'
import ParagraphStyle from '@/components/atoms/typo/ParagraphStyle'

const CardStyle = tw`block bg-white shadow-md rounded-lg md:mx-12 mx-8 transform overflow-hidden duration-500 hover:rotate-0`
const TextAreaStyle = tw`p-4`

const Works: React.FC = () => {
  return (
    <section css={SectionStyle(tw`lg:pb-0 pb-12`)}>
      <Headline>Works</Headline>
      <div tw="lg:mt-16 mt-8 w-full">
        <SlideShow>
          <a
            href="https://tamaplaza.localplan.app"
            target="_blank"
            css={css(CardStyle, tw`rotate-6 md:w-96 w-72`)}
          >
            <StaticImage
              src="../../../static/images/tamaplan.jpg"
              alt="TamaPlan"
            />
            <div css={css(TextAreaStyle)}>
              <h3 css={H3Style(tw`text-gray-800`)}>地域活性化サービス</h3>
              <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
                企画開発デザイン全分野担当
                <br />
                使用技術
                <br />
                Gatsby.js, Netlify, Photoshop
              </p>
            </div>
          </a>
          <a
            href="https://yokikana.info"
            target="_blank"
            css={css(CardStyle, tw`md:w-80 w-60 -rotate-3 px-4`)}
          >
            <StaticImage
              src="../../../static/images/yokikana.png"
              alt="良きかな"
              objectFit="contain"
              css={tw`min-height[200px]`}
            />
            <div css={css(TextAreaStyle, tw`px-0`)}>
              <h3 css={H3Style(tw`text-gray-800`)}>
                青葉区イベント検索サービス
                <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
                  開発,webデザイン担当
                  <br />
                  使用技術
                  <br />
                  Gatsby.js, Netlify, Contentful, Firebase, SendGrid
                </p>
              </h3>
            </div>
          </a>
          <a
            href="https://tamat.jp"
            target="_blank"
            css={css(CardStyle, tw`md:w-64 w-48 rotate-12 px-4`)}
          >
            <StaticImage
              src="../../../static/images/tamat_logo.png"
              alt="合同会社TamaT"
            />
            <div css={css(TextAreaStyle, tw`px-0`)}>
              <h3 css={H3Style(tw`text-gray-800`)}>合同会社TamaT</h3>
              <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
                代表取締役を勤める会社
                <br />
                webサイト制作、webアプリ開発、RPAツール開発
              </p>
            </div>
          </a>
          <a
            href="https://dhaj.jp"
            target="_blank"
            css={css(CardStyle, tw`-rotate-3 md:w-80 w-60 px-4`)}
          >
            <StaticImage src="../../../static/images/dhaj.jpg" alt="TamaPlan" />
            <div css={css(TextAreaStyle, tw`px-0`)}>
              <h3 css={H3Style(tw`text-gray-800`)}>
                日本デリシャスハーバリウム協会様
              </h3>
              <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
                開発デザイン全分野担当
                <br />
                使用技術
                <br />
                Wordpress, adobeXD
              </p>
            </div>
          </a>
          <a
            href="https://localcompany.jp"
            target="_blank"
            css={css(CardStyle, tw`rotate-6 md:w-80 w-60`)}
          >
            <StaticImage
              src="../../../static/images/localcompany.png"
              alt="ローカルカンパニー"
            />
            <div css={css(TextAreaStyle)}>
              <h3 css={H3Style(tw`text-gray-800`)}>
                株式会社ローカルカンパニー様
              </h3>
              <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
                開発デザイン全分野担当
                <br />
                使用技術
                <br />
                Wordpress, adobeXD
              </p>
            </div>
          </a>
          <a
            href="https://tama-house.jp"
            target="_blank"
            css={css(CardStyle, tw`-rotate-3 md:w-80 w-60`)}
          >
            <StaticImage
              src="../../../static/images/tamahouse.jpg"
              alt="たまハウス"
            />
            <div css={css(TextAreaStyle)}>
              <h3 css={H3Style(tw`text-gray-800`)}>株式会社たまハウス様</h3>
              <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
                開発デザイン全分野担当
                <br />
                使用技術
                <br />
                Wordpress, adobeXD
              </p>
            </div>
          </a>
        </SlideShow>
        <div tw="text-center lg:mt-20 md:mt-12 mt-8">
          <Link
            tw="bg-red-500 text-white text-xl px-12 py-3 shadow-xl rounded-xl font-medium tracking-wider hover:bg-red-700 transition-duration[300ms]"
            to="/works/"
          >
            実績一覧
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Works

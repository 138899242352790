import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import SectionStyle from '../atoms/util/SectionStyle'
import WrapForm from '../molecules/WrapForm'
import Wrapper from '../atoms/util/Wrapper'
import Headline from '../atoms/util/Headline'
import tw from 'twin.macro'

const InputStyle = tw`block my-5 rounded-sm bg-white text-gray-100 bg-opacity-40 shadow-lg backdrop-blur-sm outline-none placeholder-gray-200 px-2 py-1 focus:bg-opacity-20 duration-500 resize-none w-full`
const LabelStyle = tw`text-white`

const Contact: React.FC = () => {
  return (
    <section css={SectionStyle(tw`grid`)}>
      <StaticImage
        css={tw`grid-area[1/1]`}
        src="../../../static/images/contact.jpg"
        alt="背景"
        layout="fullWidth"
        aspectRatio={3 / 1}
        formats={['auto', 'webp', 'avif']}
        placeholder="blurred"
      />
      <div css={tw`grid-area[1/1] relative grid place-items-center　my-20`}>
        <Wrapper>
          <WrapForm>
            <h2 tw="text-white md:text-3xl text-xl text-center font-semibold tracking-wider mb-8">
              Contact
              <span tw="p-1 bg-yellow-500 inline-block rounded-full ml-1.5 relative">
                <span tw="p-1 bg-yellow-500 block rounded-full ml-1.5 animate-ping absolute top-0 -left-1.5"></span>
              </span>
            </h2>
            <label css={LabelStyle}>お名前</label>
            <input
              required
              placeholder="田中 太郎"
              type="text"
              name="name"
              css={InputStyle}
            />
            <label css={LabelStyle}>email</label>
            <input
              required
              placeholder="abc@xxx.com"
              type="text"
              name="email"
              css={InputStyle}
            />
            <label css={LabelStyle}>ご用件</label>
            <textarea
              required
              name="message"
              cols={30}
              rows={10}
              css={InputStyle}
              placeholder="ご用件をご記入下さい。"
            ></textarea>
            <button tw="bg-white text-gray-100 bg-opacity-40 shadow-lg backdrop-blur-sm block w-full py-2 hover:bg-opacity-20 duration-500">
              送信
            </button>
          </WrapForm>
        </Wrapper>
      </div>
    </section>
  )
}

export default Contact

import React from 'react'
import 'twin.macro'

const Headline: React.FC = ({ children }) => (
  <h2 tw="text-gray-700 md:text-3xl text-xl text-center font-semibold tracking-wider">
    {children}
    <span tw="p-1 bg-blue-500 inline-block rounded-full ml-1.5 relative">
      <span tw="p-1 bg-blue-500 block rounded-full ml-1.5 animate-ping absolute top-0 -left-1.5"></span>
    </span>
  </h2>
)

export default Headline

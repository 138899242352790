import React from 'react'
import 'twin.macro'
import { keyframes, css } from '@emotion/react'

const loopSlide = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`

const SlideShow: React.FC<{}> = ({ children }) => {
  const content = [children, children]
  return (
    <div tw="flex items-center overflow-hidden py-6">
      {content.map((item, i) => (
        <div
          tw="flex items-center"
          css={css`
            animation: ${loopSlide} 60s infinite linear 1s both;
          `}
          key={i}
        >
          {item}
        </div>
      ))}
    </div>
  )
}

export default SlideShow
